'use client';

import {Button}         from 'foundations/button/button';
import {useTranslation} from 'foundations/i18n/i18n';
import {Icon}           from '@glimpse/glass';
import AstroRocketImage from 'foundations/brand/astro/astro-rocket-pack.png';
import {SupportedBy}    from 'features/shared/supported-by/supported-by';
import Image            from 'next/image';
import OverviewImage    from 'features/home/overview-image.png';
import ComponentsImage  from 'features/home/components-image.png';
import ReposImage       from 'features/home/repos-image.png';
import ScaffoldsImage   from 'features/home/scaffolds-image.png';
import InsightsImage    from 'features/home/insights-image.png';
import PipelinesImage   from 'features/home/pipelines-image.png';

export default function HomePage() {
    const {t} = useTranslation();
    return (
        <div>
            <div className={`mx-2 mb-16 md:mx-6 lg:mx-10 mt-2 rounded-2xl pt-12 pb-36 lg:pb-44 px-2 bg-brand-purple bg-opacity-10`}>
                <div className={'text-center mb-6'}>
                    <h2 className={'!leading-tight block mx-auto text-4xl lg:text-6xl mb-4 max-w-3xl font-black'}>{t('Mission Control for software teams')}</h2>
                    <span className={'block lg:text-xl mx-auto max-w-2xl mb-4 opacity-80'}>{t('Take control of the chaos and simplify your day. Glimpse helps high performing software teams make the most of their work, so you can save time and stress less.')}</span>
                </div>
                <div className={'flex justify-center mb-12'}>
                    <Button href={'https://app.withglimpse.com/signup'}>{t('Get Started Free')}</Button>
                </div>
            </div>
            <div className={'-mt-[450px] lg:-mt-[500px] relative'}>
                <Image src={OverviewImage} alt={'Glimpse dashboard'} className={'object-cover object-top rounded-2xl mx-auto block max-w-[90vw] xl:max-w-6xl max-h-72 border-8 border-opacity-20 border-white'}/>
                <Image src={AstroRocketImage} height={300} alt={''} className={'hidden lg:block absolute top-[5vw] right-[5vw]'}/>
            </div>
            <div className={'mb-20 mt-16'}>
                <div className={'site-width'}>
                    <SupportedBy/>
                </div>
            </div>
            <div className={'site-width'}>
                <h2 className={'block text-4xl lg:text-5xl text-center font-black mb-3'}>{t('Everything your team is looking for')}</h2>
                <p className={'text-center text-xl mb-10'}>{t('A complete platform that brings the world\'s of software development together')}</p>
                <div className={'grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-2 items-stretch'}>
                    <div className={'pt-4 px-4 bg-white rounded-xl col-span-2 lg:col-span-3 text-center relative overflow-hidden'}>
                        <h3 className={'font-bold text-xl mb-2'}>{t('Service catalog')}</h3>
                        <p className={'max-w-md mb-4 block mx-auto'}>{t('A full-featured catalog to discover, improve and standardize your services and apps')}</p>
                        <Image className={'h-32 object-cover object-left-top'} src={ComponentsImage} alt={''}/>
                        <div className={'-mt-12 bottom-0 inset-x-0 h-12 from-white to-transparent bg-gradient-to-t z-50 absolute'}/>
                    </div>
                    <div className={'pt-4 px-4 bg-white rounded-xl col-span-2 lg:col-span-3 text-center relative overflow-hidden'}>
                        <h3 className={'font-bold text-xl mb-2'}>{t('Code management')}</h3>
                        <p className={'max-w-md mb-4 block mx-auto'}>{t('30,000 foot view of your codebases, PRs and code health. Dive as deeply as needed.')}</p>
                        <Image className={'h-32 object-cover object-left-top'} src={ReposImage} alt={''}/>
                        <div className={'-mt-12 bottom-0 inset-x-0 h-12 from-white to-transparent bg-gradient-to-t z-50 absolute'}/>
                    </div>
                    <div className={'pt-4 px-4 bg-white rounded-xl col-span-2 text-center relative overflow-hidden'}>
                        <h3 className={'font-bold text-xl mb-2'}>{t('Pipeline orchestration')}</h3>
                        <p className={'max-w-md mb-4 block mx-auto'}>{t('All pipelines in one place, from Github Actions to Terraform Runs. Instantly understand application state.')}</p>
                        <Image className={'h-32 object-cover object-left-top'} src={PipelinesImage} alt={''}/>
                        <div className={'-mt-12 bottom-0 inset-x-0 h-12 from-white to-transparent bg-gradient-to-t z-50 absolute'}/>
                    </div>
                    <div className={'pt-4 px-4 bg-white rounded-xl col-span-2 text-center relative overflow-hidden'}>
                        <h3 className={'font-bold text-xl mb-2'}>{t('Developer automation')}</h3>
                        <p className={'max-w-md mb-4 block mx-auto'}>{t('Generate new services with a single click, automate repetitive tasks and enable developers through SOPs.')}</p>
                        <Image className={'h-32 object-cover object-top'} src={ScaffoldsImage} alt={''}/>
                        <div className={'-mt-12 bottom-0 inset-x-0 h-12 from-white to-transparent bg-gradient-to-t z-50 absolute'}/>
                    </div>
                    <div className={'pt-4 px-4 bg-white rounded-xl col-span-2 text-center relative overflow-hidden'}>
                        <h3 className={'font-bold text-xl mb-2'}>{t('Insights and recommendations')}</h3>
                        <p className={'max-w-md mb-4 block mx-auto'}>{t('No nonsense metrics that actually help you understand your team. See where your org stacks against industry benchmarks.')}</p>
                        <Image className={'h-32 object-cover object-left-top'} src={InsightsImage} alt={''}/>
                        <div className={'-mt-12 bottom-0 inset-x-0 h-12 from-white to-transparent bg-gradient-to-t z-50 absolute'}/>
                    </div>
                </div>
            </div>
            <div className={'site-width pb-24 pt-24'}>
                <h2 className={'block text-5xl text-center font-black mb-12 max-w-xl m-auto leading-tight'}>{t('Built with the foundations you expect')}</h2>
                <div className={'grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-y-12'}>
                    <div>
                        <Icon fa={['fal', 'clock']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Get Started Quickly')}</span>
                        <span className={'text-slate-700'}>{t('Integrate with our developer-friendly APIs or choose low-code and pre-built solutions.')}</span>
                    </div>
                    <div>
                        <Icon fa={['fal', 'plug']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Integrate your favourite apps')}</span>
                        <span className={'text-slate-700'}>{t('Integrate Glimpse with your favourite tools, including Github, Jira, Terraform and more.')}</span>
                    </div>
                    <div>
                        <Icon fa={['fal', 'box-open']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Works out of the box')}</span>
                        <span className={'text-slate-700'}>{t('Get up and running in minutes with reasonable defaults and easy to use integrations.')}</span>
                    </div>
                    <div>
                        <Icon fa={['fal', 'brush']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Freedom to customize')}</span>
                        <span className={'text-slate-700'}>{t('Customize properties, roles and more to tweak Glimpse so it suits your team\'s needs best.')}</span>
                    </div>
                    <div>
                        <Icon fa={['fal', 'user-headset']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Great human support')}</span>
                        <span className={'text-slate-700'}>{t('Talk to an amazing team of humans that are here to help you succeed.')}</span>
                    </div>
                    <div>
                        <Icon fa={['fal', 'rabbit-running']} className={'w-3 bg-violet-200 rounded mb-1 block p-1 text-xl'}/>
                        <span className={'font-semibold block text-lg mb-1'}>{t('Real time updates')}</span>
                        <span className={'text-slate-700'}>{t('Don\'t wait on your data. Be confident that your workspace is updated in real time.')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}