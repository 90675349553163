import {useTranslation} from 'foundations/i18n/i18n';
import Image            from 'next/image';
import NotionLogo       from 'features/shared/supported-by/notion-logo.png';
import MicrosoftLogo    from 'features/shared/supported-by/microsoft-logo.png';
import PosthogLogo    from 'features/shared/supported-by/posthog-logo.png';
import SavantLogo    from 'features/shared/supported-by/savant-logo.png';
import IntercomLogo    from 'features/shared/supported-by/intercom-logo.png';

export function SupportedBy() {
    const {t} = useTranslation();
    return (
        <div className={'text-center'}>
            <span className={'mx-auto block mb-6 opacity-40 text-slate-900'}>{t('Supported by')}</span>
            <div className={'grid grid-cols-2 md:grid-cols-3 justify-center items-center gap-6 grayscale lg:flex grid-1'}>
                <Image src={NotionLogo.src} height={0} width={0} className={'h-6 w-auto opacity-50 m-auto'} alt={'Notion logo'}/>
                <Image src={MicrosoftLogo.src} height={0} width={0} className={'h-4 w-auto m-auto'} alt={'Microsoft logo'}/>
                <Image src={PosthogLogo.src} height={0} width={0} className={'h-4 w-auto opacity-50 m-auto'} alt={'Posthog logo'}/>
                <Image src={SavantLogo.src} height={0} width={0} className={'h-4 w-auto opacity-50 m-auto md:col-auto'} alt={'Savant logo'}/>
                <Image src={IntercomLogo.src} height={0} width={0} className={'h-4 w-auto opacity-90 m-auto col-span-2 md:col-auto'} alt={'Intercom logo'}/>
            </div>
        </div>
    );
}